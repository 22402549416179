const MenuItems=[
    {   
        id:0,
        title:'About',
        url:'#about',
    },
    {
        id:1,
        title:'Projects',
        url:'#project',
    },
    {
        id:3,
        title:'Contact',
        url:'#footer',
    }
]

export default MenuItems;